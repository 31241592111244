import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import QueryString from 'qs'
import ReactPaginate from 'react-paginate'

//  components
import ExperienceCard from './ExperienceCard'

//  actions
import { resetRoute } from '../../actions/nav'
import { setFilter, getExperiences, getAllFutureSchedules, setFilterMonths } from '../../actions/experience'
import { getCuisine } from '../../actions/cusineTypes'

import seoMeta from '../../utils/seo-meta.json'

// import arrowIcon from '../../icons/global/icon-arrow-right.svg'
import SEO from '../Shared/Web/SEO'

// const VERTICAL_ORIENTATION = 'vertical'
// const HORIZONTAL_ORIENTATION = 'horizontal'

const locationOptions = [
  // {
  //   value: 'London',
  //   label: 'London'
  // },
  {
    value: 'Dubai',
    label: 'Dubai'
  },
  {
    value: 'Sharjah',
    label: 'Sharjah'
  },
  {
    value: 'Abu Dhabi',
    label: 'Abu Dhabi'
  }
]
class ExperienceIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      filter: {
        current_page: 1,
        per_page: 21,
        address: null,
        cuisineTypes: null,
        // startDate: moment.utc().subtract('years', 1),
        // endDate: filter.endDate || moment.utc().add('years', 1)
        // startDate: filter.startDate || moment.utc().subtract('years', 1), // backup prior to showing all dates
        // endDate: filter.endDate || moment.utc().add('years', 1)
        startDate: moment(), // backup prior to showing all dates
        endDate: null
      },
      focusedInput: null,
      startDateFocus: null,
      endDateFocus: null
    }
    // this.onSearch();
    this.valuesDirty = false
    this.createCityList = this.createCityList.bind(this)
    this.createCuisineTypeList = this.createCuisineTypeList.bind(this)
  }

  async componentDidMount() {
    const { location, match, getCuisineAction } = this.props
    await getCuisineAction()
    const { filter } = this.state
    if (typeof window !== 'undefined') {
      this.deviceSize()
      window.addEventListener('resize', this.onResize.bind(this))
    }

    const serchQuery = location.search
    // var url = new URL(this.props.location);
    const params = new URLSearchParams(serchQuery)
    // let  params= new URLSearchParams(serchQuery);
    let pageQueryInt = 1
    const matchedParam = match.params
    if (location.search) {
      const queryObj = QueryString.parse(location.search)
      const pageQuery = queryObj['?page'] || queryObj.page
      if (pageQuery) {
        pageQueryInt = Number(pageQuery)
      }
    }
    // cousine = matchedParam.cuisine == "cuisine-all" ? "" : matchedParam.cuisine;
    let city = ''
    let cousine = ''
    if (matchedParam.cities || matchedParam.cousine) {
      city = matchedParam.cities === 'all-cities'
        ? ''
        : matchedParam.cities
      cousine = matchedParam.cousine === 'all-cuisines' ? '' : matchedParam.cousine
    }

    const startDate = params.get('start-date')
    const endDate = params.get('end-date')

    const address = []
    const filteredCuisines = []
    let filterStartDate = moment()
    let filterEndDate = ''

    if (city) {
      const allCities = city.split(',')
      allCities.forEach(key => {
        address.push({
          value: key,
          label: key
        })
      })
    }
    if (cousine) {
      const allCuisine = cousine.split(',')
      Object.keys(allCuisine).forEach(key => { // for (const key in allCuisine) {
        filteredCuisines.push({
          value: allCuisine[key],
          label: allCuisine[key]
        })
      })
    }

    // if (cousine) {
    //   // console.log({ cousine })
    //   const allCuisine = cousine.split(',')
    //   allCuisine.forEach(key => {
    //     filteredCuisines.push(cuisineTypes.filter((c) => c.name.toLowerCase() === key.toLowerCase())[0])
    //   })
    // }

    if (startDate) {
      filterStartDate = moment(startDate)
    }

    if (endDate) {
      filterEndDate = moment(endDate)
    }

    this.setState(
      {
        filter: {
          ...filter,
          cuisineTypes: filteredCuisines,
          // .map(c => {
          //   console.log({ c, filteredCuisines, cuisineTypes })
          //   if (c) return ({ value: c.id, label: c.name })
          //   return false
          // }).filter(c => c),
          startDate: filterStartDate,
          endDate: filterEndDate,
          address,
          current_page: pageQueryInt
        }
      },
      () => {
        if (endDate || startDate) {
          this.afterStateChange()
        }
        this.onSearch()
      }
    )
  }

  /* componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this))
  } */

  handleLoadMore(page) {
    const { filter } = this.state
    const currentPage = page
    this.setState(
      {
        filter: { ...filter, current_page: currentPage }
      },
      () => {
        this.onSearch()
        this.redirectPath()
      }
    )
  }

  handlePageClick = async (data) => {
    const { selected } = data
    await this.handleLoadMore(selected + 1)
    window.scrollTo({
      top: 100,
      behavior: 'smooth'
    })
  }

  async onSearch() {
    const { filter } = this.state
    const { setFilters } = this.props
    setFilters(filter)
    this.fetch()
  }

  onResize = () => {
    let windowResizeTime
    clearTimeout(windowResizeTime)
    windowResizeTime = setTimeout(() => {
      this.deviceSize()
    }, 100)
  }

  deviceSize = () => {
    // const smallDevice = window.matchMedia('(max-width: 616px)').matches
    // const orientation = smallDevice
    //   ? VERTICAL_ORIENTATION
    //   : HORIZONTAL_ORIENTATION
    // this.setState({ smallDevice, orientation })
  }

  handleLocationChange = address => {
    const { filter } = this.state

    // if(address != null && address.length > 0){
    this.setState(
      {
        filter: { ...filter, current_page: 1, address }
      },
      () => {
        this.onSearch()
        this.redirectPath()
      }
    )
  }

  handleCuisineTypeChange = cuisineTypes => {
    const { filter } = this.state
    this.setState(
      {
        filter: { ...filter, current_page: 1, cuisineTypes }
      },
      () => {
        this.onSearch()
        this.redirectPath()
      }
    )
  }

  afterStateChange = () => {
    const { filter, focusedInput, startDateFocus, endDateFocus } = this.state

    if (filter.startDate === null && filter.endDate === null) {
      // this.onSearch();
    }
    // fire onChange if either date has changed and calendar
    // has closed (whether automatic or click away)

    if (
      (!focusedInput || !startDateFocus || !endDateFocus)
      && this.valuesDirty
    ) {
      const { onChange } = this.props
      this.valuesDirty = false

      /* eslint-disable-next-line no-unused-expressions */
      onChange
        && onChange({
          startDate: filter.startDate,
          endDate: filter.endDate
        })
      this.onSearch()
      this.redirectPath()
    }
  }

  handleStartDatesChange = startDate => {
    if (!startDate) {
      return false
    }
    const { filter } = this.state
    // check if either date has actually changed
    this.setState(
      {
        filter: {
          ...filter,
          current_page: 1,
          startDate
        }
      },
      () => {
        this.onSearch()
        this.redirectPath()
        // this.afterStateChange
      }
    )
  }

  handleEndDatesChange = endDate => {
    if (!endDate) {
      return false
    }
    const { filter } = this.state
    // check if either date has actually changed
    if (endDate && !endDate.isSame(filter.endDate)) {
      this.valuesDirty = true
    }

    // let end_date = moment(endDate).format('YYYY/M/D');
    // this.props.history.push(`/experiences/?end-date=${end_date}`);

    this.setState(
      {
        filter: {
          ...filter,
          current_page: 1,
          endDate
        }
      },
      () => {
        this.onSearch()
        this.redirectPath()
        // this.afterStateChange
      }
    )
  }

  handleStartDateFocusChange = ({ focused }) => {
    // console.log('focused', focused)
    // const { filter } = this.state;
    this.setState(
      {
        startDateFocus: focused
      },
      this.afterStateChange
    )
  }

  handleEndDateFocusChange = ({ focused }) => {
    // const { filter } = this.state;
    this.setState(
      {
        endDateFocus: focused
      },
      this.afterStateChange
    )
  }

  createCityList = experiences => {
    const cityOptions = []
    if (experiences) {
      experiences.map(experience => cityOptions.push({
        value: experience.location.city,
        label: experience.location.city
      }))
      return _.uniqWith(cityOptions, _.isEqual)
    }
    locationOptions.forEach(city => {
      //  Returns all cuisine Type
      cityOptions.push({
        value: city.value,
        label: city.label
      })
    })
    return _.uniqWith(cityOptions, _.isEqual)
  }

  createCuisineTypeList = experiences => {
    const cuisineTypeOptions = []
    const { getCuisineAction, cuisineTypes } = this.props

    if (cuisineTypes.length < 1) {
      getCuisineAction()
    }
    if (experiences && cuisineTypes) {
      experiences.map(experience => experience.cuisineTypes.map(cuisineType => cuisineTypeOptions.push({
        value: cuisineType.name,
        label: cuisineType.name
      })))
      return _.sortBy(_.uniqWith(cuisineTypeOptions, _.isEqual), 'label')
    } else {
      cuisineTypes.forEach(cuisine => {
        //  Returns all cuisine Type
        cuisineTypeOptions.push({
          value: cuisine.name,
          label: cuisine.name
        })
      })
      return _.uniqWith(cuisineTypeOptions, _.isEqual)
    }
    // cuisineTypes.map(cuisine => {
    //   //  Returns all cuisine Type
    //   cuisineTypeOptions.push({
    //     value: cuisine.name,
    //     label: cuisine.name
    //   })
    // })
  }

  pageHrefBuilder = currentPage => {
    const { filter } = this.state
    // const currentPage = filter.currentPage ? filter.currentPage : 1
    const startDate = filter.startDate
      ? moment(filter.startDate).format('YYYY/M/D')
      : ''
    const endDate = filter.endDate
      ? moment(filter.endDate).format('YYYY/M/D')
      : ''
    // const endDate = filter.endDate ? moment.utc(filter.endDate).add(1, 'days').toString() : '';
    const address = filter.address ? filter.address.map(add => add.value) : []
    const cuisineTypes = filter.cuisineTypes
      ? filter.cuisineTypes.map(cuisine => cuisine.value)
      : []

    let queryString = ''
    if (address) {
      queryString = '?'
    }

    /*
    if (cuisineTypes) {
      queryString = queryString + `&cuisine-types=${cuisineTypes}`;
    } */

    const queryStringCuisine = cuisineTypes.toString()
      ? cuisineTypes.toString()
      : 'all-cuisines'
    const queryStringCity = address.toString()
      ? address.toString()
      : 'all-cities'

    if (startDate) {
      queryString += `&start-date=${startDate}`
    }

    if (endDate) {
      queryString += `&end-date=${endDate}`
    }
    return `/experiences/${queryStringCity.toLowerCase()}/${queryStringCuisine.toLowerCase()}${queryString}${currentPage ? `${queryString ? '&' : '?'}page=${currentPage}` : ''}`
  }

  renderDayContents = (date, _day) => {
    const { upcomingSchedules } = this.props
    const currentDate = new Date()
    const localDate = new Date(date)
    const localDay = localDate.getDate()
    const eventExist = upcomingSchedules.find(el => el.date === moment(localDate).format('YYYY-MM-DD'))
    return <span>{localDay} {eventExist && <span style={{ color: `${localDate < currentDate ? 'lightgray' : 'green'}`, fontSize: '7px', position: 'absolute', marginTop: '-7px', marginLeft: `${localDay > 9 ? '2px' : '5px'}` }} className="fas fa-circle" />} </span>
  }

  renderCalendarInfo = () => (
    <div style={{ minHeight: '9px' }}>
      <span style={{ padding: '0px 0px 10px 22px', display: 'block', marginTop: '-19px', position: 'absolute' }}>
        <span style={{ color: 'green', fontSize: '7px', verticalAlign: 'middle' }} className="fas fa-circle" />
        <small style={{ color: 'darkseagreen', marginLeft: '7px' }}>Active experience available!</small>
      </span>
    </div>
  )

  onPrevMonthClick = async (currentDate) => {
    const { fetchUpcomingSchedules, setLocalFilterMonths, filterDates } = this.props
    const startDate = currentDate.startOf('month').format('YYYY-MM-DD')
    const endDate = currentDate.endOf('month').format('YYYY-MM-DD')
    if (!filterDates.find(el => el === startDate)) {
      await setLocalFilterMonths(startDate)
      await fetchUpcomingSchedules(startDate, endDate)
    }
  }

  onNextMonthClic = async (currentDate) => {
    const { fetchUpcomingSchedules, setLocalFilterMonths, filterDates } = this.props
    const startDate = currentDate.startOf('month').format('YYYY-MM-DD')
    const endDate = currentDate.endOf('month').format('YYYY-MM-DD')
    if (!filterDates.find(el => el === startDate)) {
      await setLocalFilterMonths(startDate)
      await fetchUpcomingSchedules(startDate, endDate)
    }
  }

  async fetch() {
    const { fetchExperiences, fetchUpcomingSchedules, setLocalFilterMonths } = this.props
    this.setState({ isLoading: true })
    await fetchExperiences()
    await fetchUpcomingSchedules()
    const firstDate = moment().startOf('month').format('YYYY-MM-DD')
    const secondDate = moment(firstDate).add(1, 'months').startOf('month').format('YYYY-MM-DD')
    const thirdDate = moment(secondDate).add(1, 'months').startOf('month').format('YYYY-MM-DD')
    const forthDate = moment(thirdDate).add(1, 'months').startOf('month').format('YYYY-MM-DD')
    await setLocalFilterMonths([firstDate, secondDate, thirdDate, forthDate])
    this.setState({ isLoading: false })
  }

  resetFiltering() {
    this.setState(
      {
        filter: {
          address: null,
          cuisineTypes: null,
          // startDate: null,
          // endDate: null,
          startDate: moment.utc(new Date()), // backup prior to showing all dates
          endDate: null,
          orientation: null,
          smallDevice: null
        },
        focusedInput: null,
        startDateFocus: null,
        endDateFocus: null
      },
      () => {
        this.onSearch()
        this.redirectPath()
      }
    )
  }

  redirectPath() {
    const { filter } = this.state
    const currentPage = filter.current_page ? filter.current_page : 1
    const startDate = filter.startDate
      ? moment(filter.startDate).format('YYYY/M/D')
      : ''
    const endDate = filter.endDate
      ? moment(filter.endDate).format('YYYY/M/D')
      : ''
    // const endDate = filter.endDate ? moment.utc(filter.endDate).add(1, 'days').toString() : '';
    const address = filter.address ? filter.address.map(add => add.value) : []
    const cuisineTypes = filter.cuisineTypes
      ? filter.cuisineTypes.map(cuisine => cuisine.value)
      : []

    let queryString = ''
    if (address) {
      queryString = '?'
    }

    /*
    if (cuisineTypes) {
      queryString = queryString + `&cuisine-types=${cuisineTypes}`;
    } */

    const queryStringCuisine = cuisineTypes.toString()
      ? cuisineTypes.toString()
      : 'all-cuisines'
    const queryStringCity = address.toString()
      ? address.toString()
      : 'all-cities'

    if (startDate) {
      queryString += `&start-date=${startDate}`
    }

    if (endDate) {
      queryString += `&end-date=${endDate}`
    }

    /* this.props.history.push({
      pathname: `/experiences/${queryStringCity}/${queryStringCuisine}`,
      search: `${queryString}`
    }) */
    window.history.pushState(
      {},
      null,
      `/experiences/${queryStringCity.toLowerCase()}/${queryStringCuisine.toLowerCase()}${queryString}${currentPage ? `${queryString ? '&' : '?'}page=${currentPage}` : ''}`
    )
  }

  setCapitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  render() {
    const { experiences } = this.props
    const { allExperience } = this.props
    const { meta } = allExperience || {}
    const { total_count: totalCount, per_page: perPage } = meta || {}
    // let currentPage = 0
    // let total_count = 12
    const { filter, isLoading, startDateFocus, endDateFocus } = this.state
    // const loadMoreShow = true
    // const loadMoreShow = !!(
    //   experiences.meta
    //   && filter.per_page < experiences.meta.total_count / experiences.meta.current_page
    // )
    /* const filteredExperiences = experiences.filter(experience => {
      if (
        !filter.startDate ||
        experience.date > moment(filter.startDate).toISOString() ||
        experience.date < moment(filter.endDate).toISOString() // filter date range
      ) {
        if (
          !filter.cuisineTypes ||
          !filter.cuisineTypes.length ||
          experience.cuisineTypes == ''
        )
          return true
        const filteredCuisines = experience.cuisineTypes.filter(
          cuisine =>
            filter.cuisineTypes.filter(cz => cz.value === cuisine.name)
              .length !== 0 // filter cuisine types
        )
        return filteredCuisines.length !== 0
      }
      return false // if experience doesnt fall in the filtered date range
    }) */

    // const sortedExperiences = experiences.sort((a, b) => new Date(a.dateWithTime) - new Date(b.dateWithTime))

    const currentPage = filter.current_page ? filter.current_page - 1 : 0

    const cityOptions = this.createCityList(experiences)
    const cuisineTypeOptions = this.createCuisineTypeList(experiences)

    const gridSize = 'grid__item medium-down--one-half medium--one-half large-up--one-third'
    // const featuredGridSize = 'grid__item medium-down--one-whole medium--one-whole large-up--one-whole'

    const fieldGridSize = 'grid__item medium-down--one-half large-up--one-fifth'
    const { match } = this.props
    const matchedParam = match.params

    // cousine = matchedParam.cuisine == "cuisine-all" ? "" : matchedParam.cuisine;
    let city = ''
    let cousine = ''
    if (matchedParam.cities || matchedParam.cousine) {
      city = matchedParam.cities === 'all-cities'
        ? ''
        : matchedParam.cities.split('-')[1]
      cousine = matchedParam.cousine === 'all-cuisines' ? '' : matchedParam.cousine
    }
    const pageTitle = city || cousine
      ? `Discover Experiences for ${matchedParam.cousine} Cuisine in ${city}`
      : seoMeta.experiences.title

    const cityValue = (filter.address !== null) ? filter.address.map(x => ({
      value: this.setCapitalize(x['value']),
      label: this.setCapitalize(x['value'])
    })) : null
    const cuisineTypevalue = (filter.cuisineTypes !== null) ? filter.cuisineTypes.map(x => ({
      value: this.setCapitalize(x['value']),
      label: this.setCapitalize(x['value'])
    })) : null

    return (
      <div className="breakbread-container mb_5">
        <SEO title={pageTitle} description={pageTitle} />
        <div className="section-header text-center" style={{ marginBottom: '0px' }}>
          <h1 className="page-header">Experiences</h1>
          <div className="field-grid--container">
            <div className="grid field-grid">
              <div className={fieldGridSize}>
                <Select
                  placeholder="City"
                  // options={locationOptions} //  hard code data
                  options={cityOptions} // dynamic data
                  onChange={this.handleLocationChange}
                  value={cityValue}
                  // isClearable={false}
                  isMulti
                  className="react-select"
                  classNamePrefix="select"
                />
              </div>
              <div className={fieldGridSize}>
                <Select
                  placeholder="Cuisine Type"
                  options={cuisineTypeOptions}
                  onChange={this.handleCuisineTypeChange}
                  value={cuisineTypevalue}
                  defaultValue={filter.cuisineTypes}
                  isClearable
                  isMulti
                  className="react-select"
                  classNamePrefix="select"
                />
              </div>
              {/* <div className="grid__item medium-down--one-half large-up--two-fifths" style={{display:"flex"}}>
                <DateRangePicker
                  startDateId="startDate"
                  startDate={filter.startDate}
                  endDateId="endDate"
                  endDate={filter.endDate}
                  onDatesChange={this.handleDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={this.handleFocusChange}
                  noBorder
                  hideKeyboardShortcutsPanel
                  orientation={orientation}
                  withPortal={smallDevice}
                  reopenPickerOnClearDates
                  showClearDates
                  // withFullScreenPortal={smallDevice}
                />

              </div> */}
              <div className="grid__item medium-down--one-half large-up--two-fifths customDateinput">
                <div className="grid field-grid">
                  <div className="grid__item small--one-half medium-down--one-half medium--one-half large-up--one-half fullWidthDateInput">
                    <div className="grid field-grid">
                      <div className="grid__item small--four-fifths medium-down--four-fifths medium--four-fifths large-up--four-fifths">
                        <SingleDatePicker
                          numberOfMonths={1}
                          // required
                          // disabled={disabled}
                          isOutsideRange={() => false}
                          displayFormat="DD/MM/YYYY"
                          date={filter.startDate}
                          onDateChange={this.handleStartDatesChange}
                          focused={startDateFocus}
                          onFocusChange={this.handleStartDateFocusChange}
                          renderDayContents={this.renderDayContents}
                          renderCalendarInfo={this.renderCalendarInfo}
                          onPrevMonthClick={this.onPrevMonthClick}
                          onNextMonthClick={this.onNextMonthClic}
                          placeholder="START DATE"
                          id="startDate"
                        />
                      </div>

                      <div className="grid__item small--one-fifth medium-down--one-fifth medium--one-fifth large-up--one-fifth">
                        <div
                          className="DateRangePickerInput_arrow DateRangePickerInput_arrow_1"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <svg
                            className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1"
                            focusable="false"
                            viewBox="0 0 1000 1000"
                          >
                            <path d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid__item  small--one-half medium-down--one-half medium--one-half large-up--one-half fullWidthDateInput">
                    <SingleDatePicker
                      numberOfMonths={1}
                      // required
                      // disabled={disabled}
                      displayFormat="DD/MM/YYYY"
                      anchorDirection="right"
                      date={filter.endDate ? filter.endDate : null /* moment.utc(new Date()) */}
                      onDateChange={this.handleEndDatesChange}
                      focused={endDateFocus}
                      onFocusChange={this.handleEndDateFocusChange}
                      renderDayContents={this.renderDayContents}
                      renderCalendarInfo={this.renderCalendarInfo}
                      onPrevMonthClick={this.onPrevMonthClick}
                      onNextMonthClick={this.onNextMonthClic}
                      placeholder="End Date"
                      id="endDate"
                    />
                  </div>
                </div>
              </div>
              <div className={fieldGridSize}>
                <button
                  onClick={() => this.resetFiltering()}
                  type="button"
                  className="breakbread-btn btn--flat customClear"
                >
                  Clear Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {isLoading && experiences.length === 0 && (
          <div className="spinner spinner--empty" />
        )}
        {!isLoading && experiences.length === 0 && (
          <div className="text-center text-warning">
            <div className="section-header section-heading">
              <h5>No Results were found for your selection</h5>
            </div>
            {/* <p>
              <button
                type="button"
                onClick={() => {
                  this.resetFiltering();
                }}
                className="btn"
              >
                Clear Search
              </button>
            </p> */}
          </div>
        )}
        {experiences.length > 0 && (
          <div>
            {/* <div className="grid">
              {typeof experiences !== 'undefined'
                && experiences.map((experience, idx) => {
                  if (idx === 0) {
                    return (
                      <div
                        key={experience.id}
                        className={`${featuredGridSize} simple-animated fadeInUpMed`}
                      >
                        <ExperienceCard experience={experience} featured />
                        <br />
                      </div>
                    )
                  } return null
                })}
            </div> */}
            <div className="grid grid--uniform">
              {typeof experiences !== 'undefined'
                && experiences.map((experience, _idx) => (
                  <div key={experience.id} className={`${gridSize} simple-animated fadeInUpMed`}>
                    <ExperienceCard experience={experience} />
                  </div>
                ))}
            </div>
            {/* <div className="grid grid--uniform">
              {typeof experiences !== 'undefined' &&
                experiences.map((experience, idx) =>
                  idx !== 0 ? (
                    <div
                      key={experience.id}
                      className={`${gridSize} simple-animated fadeInUpMed`}
                    >
                      <ExperienceCard experience={experience} />
                    </div>
                  ) : null
                )}
            </div> */}
          </div>
        )}
        {totalCount > perPage && (
          <div className="grid grid--uniform flexCenter mb_5">
            <div className="margin_5">
              <ReactPaginate
                previousLabel="Back"
                nextLabel="Next"
                breakLabel="..."
                breakClassName="break-me-hosts"
                containerClassName="pagination"
                pageCount={Math.ceil(totalCount / perPage)}
                hrefBuilder={this.pageHrefBuilder}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick}
                forcePage={currentPage}
                subContainerClassName="pages pagination"
                activeLinkClassName="btn"
                pageLinkClassName=""
                pageClassName="pagination__text"
                activeClassName="active"
              />
            </div>
          </div>
        )}

        {experiences.length > 0 && (
          <div className="grid grid--uniform flexCenter mb_5">
            {/*  <button
              className="breakbread-btn btn--flat margin_5"
              onClick={() => this.resetFiltering()}
            >
              Clear Search
            </button> */}

            {/* {loadMoreShow ? (
              <button
                type="button"
                className="btn  margin_5"
                onClick={() => this.handleLoadMore()}
              >
                Load More
              </button>
            ) : (
              ''
            )} */}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  accessToken: state.user.accessToken,
  experiences: state.experience.experienceIndex.experiences,
  // cuisineTypes: state.experience.cuisineTypes,
  filter: state.experience.filter,
  cuisineTypes: state.cuisineTypes.allCuisine,
  testCT: state.cuisineTypes,
  allExperience: state.experience.experienceIndex.allExperience,
  upcomingSchedules: state.experience.experienceIndex.upcomingSchedules,
  filterDates: state.experience.experienceIndex.filterDates
})

ExperienceIndex.defaultProps = {
  onChange: null,
  experiences: null
}

ExperienceIndex.propTypes = {
  onChange: PropTypes.func,
  setFilters: PropTypes.func.isRequired,
  fetchExperiences: PropTypes.func.isRequired,
  fetchUpcomingSchedules: PropTypes.func.isRequired,
  setLocalFilterMonths: PropTypes.func.isRequired,
  filter: PropTypes.shape({}).isRequired,
  experiences: PropTypes.arrayOf(PropTypes.shape({})),
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cities: PropTypes.string,
      cousine: PropTypes.string
    })
  }).isRequired,
  getCuisineAction: PropTypes.func.isRequired,
  cuisineTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  upcomingSchedules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterDates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allExperience: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  setFilters: filter => dispatch(setFilter(filter)),
  fetchExperiences: () => dispatch(getExperiences()),
  fetchUpcomingSchedules: (startDate, endDate) => dispatch(getAllFutureSchedules(startDate, endDate)),
  resetRoute: route => dispatch(resetRoute(route)),
  setLocalFilterMonths: (filterdates) => dispatch(setFilterMonths(filterdates)),
  getCuisineAction: () => dispatch(getCuisine())
})

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceIndex)
