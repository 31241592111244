import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import LogoNegative from './LogoNegative'
import SubscriptionModal from '../../PagesMarketing/HomeScreen/SubscriptionModal'
import Icon from './Icon'

const setCookie = (name, value, days) => {
  var expires = ""
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}
const getCookie = (name) => {
  var nameEQ = name + "="
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export default class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showGDPR: false
    }
  }

  componentDidMount() {
    let getGDPRCookie = getCookie('getGDPRCookie')
    if (getGDPRCookie == null) {
      setTimeout(() => { this.setState({ showGDPR: true }) }, 3000)
    } else {
      this.setState({ showGDPR: false })
    }
  }

  setGDPRCookie = () => {
    setCookie('getGDPRCookie', 'GDPR policy accepted', 182)
    this.setState({ showGDPR: false })
  }

  render() {
    var d = new Date()
    var year = d.getFullYear()
    return (
      <footer id="footer" className="breakbread-footer">
        <section>
          <div className="breakbread-footer-wrap">
            <div className="breakbread-container">
              <div className="breakbread-container-inner">
                <div className="row breakbread-flexify-equal-columns" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                  <div className="col-md-2 breakbread-footer-col">
                    <h6>Explore</h6>
                    <div className="breakbread-list-group">
                      {/* <Link className="breakbread-list-group-item" to="/how-it-works">
                        How it Works
                      </Link> */}
                      <Link className="breakbread-list-group-item" to="/experiences">
                        Explore Experiences
                      </Link>
                      <Link className="breakbread-list-group-item" to="/hosts">
                        Browse Hosts
                      </Link>
                      <Link className="breakbread-list-group-item" to="/become-a-host">
                        Become a Host
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-2 breakbread-footer-col">
                    <h6>BreakBread.com</h6>
                    <div className="breakbread-list-group">
                      <Link className="breakbread-list-group-item" to="/about-us">
                        Our Story
                      </Link>

                      <Link className="breakbread-list-group-item" to="/terms-and-conditions">
                        Terms &amp; Conditions
                      </Link>

                      <Link className="breakbread-list-group-item" to="/privacy-policy">
                        Privacy Policy
                      </Link>

                      <Link className="breakbread-list-group-item" to="/cookie-policy">
                        Cookie Policy
                      </Link>

                      {/* <Link
                        className="breakbread-list-group-item"
                        to="/contact-us"
                      >
                        Contact Us
                      </Link> */}
                    </div>
                  </div>
                  {/* <div className="col-md-2 breakbread-footer-col">
                    <h6>Customer Service</h6>
                    <div className="breakbread-list-group">
                      <Link
                        to="/privacy-policy"
                        className="breakbread-list-group-item"
                      >
                        Privacy Policy
                      </Link>

                      <Link
                        to="/terms-and-conditions"
                        className="breakbread-list-group-item"
                      >
                        Terms &amp; Conditions
                      </Link>

                      <Link
                        to="/refund-policy"
                        className="breakbread-list-group-item"
                      >
                        Refund Policy
                      </Link>
                    </div>
                  </div> */}

                  <div className="col-md-3 col-offset-md-3 breakbread-footer-col">
                    <h6>Get in touch</h6>
                    <p>
                      <a href="mailto:support@breakbread.com" target="_top">
                        support@breakbread.com
                      </a>
                      <br />
                      {/* +971 (6) 554 7222<br />
                      M-F, 9:00AM - 5:00PM GST */}
                    </p>

                    <ul className="list-icon-inline site-footer__social-icons social-icons">
                      {/* <li>
                        <a
                          className="social-icons__link"
                          href="https://www.facebook.com/breakbread.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="BreakBread  on Facebook"
                        >
                          <svg
                            xmlns="https://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width={20}
                            height={20}
                            className="icon"
                          >
                            <path
                              fill="currentColor"
                              d="M18.05.811q.439 0 .744.305t.305.744v16.637q0 .439-.305.744t-.744.305h-4.732v-7.221h2.415l.342-2.854h-2.757v-1.83q0-.659.293-1t1.073-.342h1.488V3.762q-.976-.098-2.171-.098-1.634 0-2.635.964t-1 2.72V9.47H7.951v2.854h2.415v7.221H1.413q-.439 0-.744-.305t-.305-.744V1.859q0-.439.305-.744T1.413.81H18.05z"
                            />
                          </svg>
                          <span className="icon__fallback-text">Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="social-icons__link"
                          href="https://twitter.com/breakbread.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="BreakBread  on Twitter"
                        >
                          <svg
                            xmlns="https://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width={20}
                            height={20}
                            className="icon"
                          >
                            <path
                              fill="currentColor"
                              d="M19.551 4.208q-.815 1.202-1.956 2.038 0 .082.02.255t.02.255q0 1.589-.469 3.179t-1.426 3.036-2.272 2.567-3.158 1.793-3.963.672q-3.301 0-6.031-1.773.571.041.937.041 2.751 0 4.911-1.671-1.284-.02-2.292-.784T2.456 11.85q.346.082.754.082.55 0 1.039-.163-1.365-.285-2.262-1.365T1.09 7.918v-.041q.774.408 1.773.448-.795-.53-1.263-1.396t-.469-1.864q0-1.019.509-1.997 1.487 1.854 3.596 2.924T9.81 7.184q-.143-.509-.143-.897 0-1.63 1.161-2.781t2.832-1.151q.815 0 1.569.326t1.284.917q1.345-.265 2.506-.958-.428 1.386-1.732 2.18 1.243-.163 2.262-.611z"
                            />
                          </svg>
                          <span className="icon__fallback-text">Twitter</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="social-icons__link"
                          href="https://www.pinterest.com/breakbread.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="BreakBread  on Pinterest"
                        >
                          <svg
                            xmlns="https://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width={20}
                            height={20}
                            className="icon"
                          >
                            <path
                              fill="currentColor"
                              d="M9.958.811q1.903 0 3.635.744t2.988 2 2 2.988.744 3.635q0 2.537-1.256 4.696t-3.415 3.415-4.696 1.256q-1.39 0-2.659-.366.707-1.147.951-2.025l.659-2.561q.244.463.903.817t1.39.354q1.464 0 2.622-.842t1.793-2.305.634-3.293q0-2.171-1.671-3.769t-4.257-1.598q-1.586 0-2.903.537T5.298 5.897 4.066 7.775t-.427 2.037q0 1.268.476 2.22t1.427 1.342q.171.073.293.012t.171-.232q.171-.61.195-.756.098-.268-.122-.512-.634-.707-.634-1.83 0-1.854 1.281-3.183t3.354-1.329q1.83 0 2.854 1t1.025 2.61q0 1.342-.366 2.476t-1.049 1.817-1.561.683q-.732 0-1.195-.537t-.293-1.269q.098-.342.256-.878t.268-.915.207-.817.098-.732q0-.61-.317-1t-.927-.39q-.756 0-1.269.695t-.512 1.744q0 .39.061.756t.134.537l.073.171q-1 4.342-1.22 5.098-.195.927-.146 2.171-2.513-1.122-4.062-3.44T.59 10.177q0-3.879 2.744-6.623T9.957.81z"
                            />
                          </svg>
                          <span className="icon__fallback-text">Pinterest</span>
                        </a>
                      </li> */}
                      <li>
                        <a className="social-icons__link" href="https://wa.me/+971508001435" target="_blank" rel="noopener noreferrer" title="BreakBread on WhatsApp">
                          <Icon iconName={'WhatsApp'} />
                          <span className="icon__fallback-text">WhatsApp</span>
                        </a>
                      </li>
                      <li>
                        <a className="social-icons__link" href="https://www.instagram.com/breakbreaddubai/" target="_blank" rel="noopener noreferrer" title="BreakBread  on Instagram">
                          <Icon iconName={'Instagram'} />
                          <span className="icon__fallback-text">Instagram</span>
                        </a>
                      </li>
                      <li>
                        <a className="social-icons__link" href="https://www.facebook.com/breakbreaddubai/" target="_blank" rel="noopener noreferrer" title="BreakBread on FaceBook">
                          <Icon iconName={'Facebook'} />
                          <span className="icon__fallback-text">Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a className="social-icons__link" href="https://www.tiktok.com/@breakbreaddubai" target="_blank" rel="noopener noreferrer" title="BreakBread on TikTok">
                          <Icon iconName={'TikTok'} />
                          <span className="icon__fallback-text">TikTok</span>
                        </a>
                      </li>
                      <li>
                        <a className="social-icons__link" href="https://www.linkedin.com/company/breakbread/" target="_blank" rel="noopener noreferrer" title="BreakBread on LinkedIn">
                          <Icon iconName={'LinkedIn'} />
                          <span className="icon__fallback-text">LinkedIn</span>
                        </a>
                      </li>
                      {/* <li>
                        <a className="social-icons__link" href="#" target="_blank" rel="noopener noreferrer" title="BreakBread on Twitter">
                          <Icon iconName={'Twitter'} />
                          <span className="icon__fallback-text">Twitter</span>
                        </a>
                      </li> */}
                      <li>
                        <a className="social-icons__link" href="https://www.snapchat.com/add/breakbreaddxb" target="_blank" rel="noopener noreferrer" title="BreakBread on SnapChat">
                          <Icon iconName={'SnapChat'} />
                          <span className="icon__fallback-text">Snapchat</span>
                        </a>
                      </li>
                      {/* <li>
                        <a className="social-icons__link" href="#" target="_blank" rel="noopener noreferrer" title="BreakBread on Youtube">
                          <Icon iconName={'Youtube'} />
                          <span className="icon__fallback-text">Youtube</span>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="breakbread-footer-sub clearfix">
          <div className="breakbread-container">
            <div className="breakbread-container-inner">
              <div className="breakbread-footer-sub-links col-md-5">
                <ul className="list-inline">
                  <li>
                    © {year}, All rights reserved *
                    <br />
                    BreakBread LTD
                  </li>
                </ul>
              </div>
              <div className="breakbread-footer-sub__logo col-md-2">
                <LogoNegative />
              </div>
            </div>
          </div>
        </div>
        {this.state.showGDPR ? (
          <div className="cookies_Alert">
            <div className="breakbread-container cookies__container">
              <div className="cookies__content">
                <div className="cookies__text">
                  We use cookies to offer you a better browsing experience, analyse site traffic, and personalise content. By using our site, you consent to our use of cookies as stated in our{' '}
                  <a href="/cookie-policy" aria-current="page" className="" target="_blank">
                    Cookie{' '}
                  </a>
                  and{' '}
                  <a href="/privacy-policy" className="cookies__link" target="_blank">
                    {' '}
                    Privacy{' '}
                  </a>{' '}
                  policies.
                </div>
              </div>
              <div
                className="btn"
                onClick={() => {
                  this.setGDPRCookie()
                }}
              >
                Accept
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <SubscriptionModal
          title="Join the Community"
          description="Join our community of passionate foodies and talented cooks!"
          title2="Stay Up to Date"
          description2="Subscribe to our newsletter to receive updates on upcoming experiences and our latest news."
          image1={require('../../../images/home/join-the-community.jpeg')}
          signBtn="Signup"
          subsBtn="Subscribe"
        />
      </footer>
    )
  }
}
