import CountryRegionData from 'country-region-data/data.json'
import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import { filterCountries } from '../../../utils'
import { currencyOptions } from '../../../constants/currency'
import inputPropTypes from './propTypes'

const setOptions = ({ selectType, options, whitelist, blacklist, input }) => {
  if (input.name == 'hostAddressFieldsCountryCode') {
    whitelist = ['AE', 'GB']
  }
  
  const selectedData = input && input.value && typeof input.value === 'object' && !input.value.value ?input.value.map(el=>el.id):[]

  let parsedOptions
  switch (selectType) {
  case 'country':
    parsedOptions = filterCountries(CountryRegionData, whitelist, blacklist)
    break
    /* case 'language':
      parsedOptions = filterCountries(CountryRegionData, whitelist, blacklist)
      break */
  case 'preferredCurrency':
    parsedOptions = currencyOptions
    break
  default:
    parsedOptions = options.filter(el=>!el.id || (el.id && !selectedData.includes(el.id)))
  }
  return parsedOptions
}

/**
 * Retrieves the selection from a value
 * @param  {object} props   object containing component props
 * @param  {array} options the react select values
 * @return {array}         values mutated for react-select
 */
const retrieveSelected = (
  { input, valueKey = 'value', labelKey = 'label' },
  options
) => {
  // eslint-disable-next-line no-console
  //console.log('input', input, options)
  let selectedOption = typeof input !== 'undefined' ? input.value : {}

  const optionsDefined = typeof options !== 'undefined'
  const inputIsObject = typeof input.value === 'object'
  const inputIsString = typeof input.value === 'string'
  const inputIsInteger = typeof input.value === 'number'
  const valueisArray = input.value.length

  if ((inputIsString || inputIsInteger) && optionsDefined) {
    selectedOption = options.filter(
      object =>
        object[labelKey] === input[valueKey] ||
        object[valueKey] === input[valueKey] ||
        object[valueKey] === input.name ||
        object[labelKey] === input.name ||
        object[valueKey] === input.id
    )
  }
  if (inputIsObject && optionsDefined && valueisArray) {
    if (input.value[0][valueKey]) {
      return selectedOption
    }
    selectedOption = input.value.map(value => {
      const filteredOption = options.filter(
        object => object[valueKey] === value[valueKey] || object.id === value.id
      )
      if (filteredOption.length) {
        return filteredOption[0]
      }
      return {id:value.id, value: value.id, label: value.name}
    })
  }

  if (input.value.id) {
    selectedOption.id = input.value.id
  }
  if (selectedOption.length) {
    // if array
    selectedOption = [...new Set(selectedOption)] // removes duplicates.
  }

  return selectedOption
}
const getLabelKey = (option, labelKey = 'label') => option[labelKey]
const getValueKey = (option, valueKey = 'value') => option[valueKey]

const renderError = ({ meta: { touched, error } }) =>
  touched && error ? (
    <p className="common-p-error">
      <span className="form-error">{error}</span>
    </p>
  ) : (
    false
  )

const renderSelect = props => {
  const {
    input,
    label,
    field,
    placeholder,
    isClearable,
    isSearchable,
    isMulti = false,
    valueKey,
    labelKey,
    displayLabel,
    meta: { touched, error },
    options
  } = props

  const selectOptions = setOptions(props)
  const selected = retrieveSelected(props, selectOptions)
  const fieldPresent = typeof field !== 'undefined'
  const fieldName = fieldPresent ? field.name : input.name
  const fieldLabel = fieldPresent ? field.label : label
  const errored = touched && error
  
  const inputValidationClass = errored
    ? 'react-select input--error'
    : 'react-select'
  const labelValidationClass = errored ? 'error' : ''

  const showLabel = typeof displayLabel !== 'undefined' ? displayLabel : true
  return (
    <React.Fragment>
      {fieldLabel && showLabel && (
        <label htmlFor={fieldName} className={`${labelValidationClass}`}>
          {fieldLabel}
        </label>
      )}

      <Select
        onChange={input.onChange}
        // onBlur={() => {
        //   input.onBlur(selected || input.value)
        // }}
        onBlur={event => event.preventDefault()}
        id={fieldLabel}
        options={selectOptions}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isMulti={isMulti}
        getOptionLabel={option => getLabelKey(option, labelKey)}
        getOptionValue={option => getValueKey(option, valueKey)}
        value={selected}
        className={inputValidationClass}
        classNamePrefix="select"
      />
      {renderError(props)}
    </React.Fragment>
  )
}

renderSelect.defaultProps = {
  selectType: null
}

renderError.propTypes = { meta: PropTypes.shape({}).isRequired }
renderSelect.propTypes = {
  ...inputPropTypes
}

export default renderSelect
