import React, { Component } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { currentUserPropTypes } from '../../../../constants/composed-proptypes'
import { updateUserBankDetails } from '../../../../actions/user/update'
import * as fields from '../../Fields/formFields'
import { bindActionCreators } from 'redux'
import * as userActionCreators from '../../../../actions/user'
import api from '../../../../utils/api'
import Select from 'react-select'
import RenderDateField from '../../Fields/renderDateField'
import SimpleReactValidator from 'simple-react-validator'
import AvatarUpload from '../../Fields/AvatarUpload'
import { getUser } from '../../../../actions/user/getUser'
import '../User/AccountDetails.css'

class BankDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bankAccountName: "",
      bankName: "",
      bankAccountNumber: "",
      bankIbanNumber: "",
      bankCity: "",
      bankCountry: "",
      bankSwiftCode: "",
      selectedCity:"",
      bankdetailsProceed:false
    }
    this.validator = new SimpleReactValidator()
  }

  componentDidMount = async () => {
    await this.props.userActions.getBankDetail()
    let { bankAccountName, bankName, bankAccountNumber, bankIbanNumber, bankCity, bankCountry, bankSwiftCode } = this.props.currentUser.attributes
    this.setState({ bankAccountName, bankName, bankAccountNumber, bankIbanNumber, bankCity, bankCountry, bankSwiftCode })
  }

  setFieldValue = (value, field) => {
    this.setState({ [field]: value })
  }

  submitBankDetail = () => {
    if (this.validator.allValid()) {

      this.props.updateUserBankDetails(this.state)
    } else {
      this.validator.showMessages()
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate()
    }

  }


  render() {
    let contries = []
    let { bankAccountName, bankName, bankAccountNumber, bankIbanNumber, bankCity, bankCountry, bankSwiftCode } = this.state
    let selectedCountry = { value: "" }
    let countryOptions = typeof this.props.countryOptions !="undefined" ? this.props.countryOptions : []
    for (let key in countryOptions) {
      let option = {
        value: countryOptions[key].alpha2,
        label: countryOptions[key].name
      }
      if (bankCountry == countryOptions[key].alpha2) {
        selectedCountry = option
      }
      if(option.value == "AE" || option.value == "GB" )
        contries.push(option)

    }
    if (!selectedCountry.value) { selectedCountry = this.state.selectedCountry }

    let cityOptions=[
      {value: "Dubai",label: "Dubai"},
      {value: "London",label: "London"}
    ]

    let selectedCity = { value: "" }
    for (let key in cityOptions) {
      
      if (bankCity == cityOptions[key].value) {
        selectedCity= cityOptions[key]
      }
     
    }


    return (
      <form id="banForm" className="bb-generic-form">
        <div className="form-body form-body--full ">
          <div className="grid grid--uniform">
            <div className="form-column grid__item small--one-whole ">
              <div className="grid grid--uniform">
                <div className="grid__item small--one-whole medium-up--full">
                  <div className="grid grid--uniform">
                    <div className="grid__item medium-up--one-half large-up--half">
                      <label>Account Name *</label>
                      <input
                        type="text"
                        className="fullWidthInput"
                        name="bankAccountName"
                        value={bankAccountName || ''}
                        onChange={e => {
                          this.setFieldValue(e.target.value, 'bankAccountName')
                        }}
                        placeholder="Acount holder name"
                      />
                      {this.validator.message('bankAccountName', this.state.bankAccountName, 'required|max:256', {
                        messages: { default: 'Please enter account  holder name and it should have max 256 character.' },
                        className: 'form-error'
                      })}
                    </div>

                    <div className="grid__item medium-up--one-half large-up--half">
                      <label>Account Number *</label>
                      <input
                        type="text"
                        className="fullWidthInput"
                        name="bankAccountNumber"
                        value={bankAccountNumber || ''}
                        onChange={e => {
                          this.setFieldValue(e.target.value, 'bankAccountNumber')
                        }}
                        placeholder="Acount number"
                      />
                      {this.validator.message('bankAccountNumber', this.state.bankAccountNumber, 'required|max:32', {
                        messages: { default: 'Please enter account number and it should have max 32 character.' },
                        className: 'form-error'
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid--uniform">
                <div className="grid__item small--one-whole medium-up--full">
                  <div className="grid grid--uniform">
                    <div className="grid__item medium-up--one-half large-up--half">
                      <label>IBAN Number *</label>
                      <input
                        type="text"
                        className="fullWidthInput"
                        value={bankIbanNumber || ''}
                        name="bankIbanNumber"
                        onChange={e => {
                          this.setFieldValue(e.target.value, 'bankIbanNumber')
                        }}
                        placeholder="IBAN number"
                      />
                      {this.validator.message('bankIbanNumber', this.state.bankIbanNumber, 'required|max:64', {
                        messages: { default: 'Please enter IBAN number and it should have max 64 character.' },
                        className: 'form-error'
                      })}
                    </div>

                    <div className="grid__item medium-up--one-half large-up--half">
                      <label>Bank Name *</label>
                      <input
                        type="text"
                        className="fullWidthInput"
                        value={bankName || ''}
                        name="bankName"
                        onChange={e => {
                          this.setFieldValue(e.target.value, 'bankName')
                        }}
                        placeholder="Bank name"
                      />
                      {this.validator.message('bankName', this.state.bankName, 'required|max:256', {
                        messages: { default: 'Please enter bank name and it should have max 256 character.' },
                        className: 'form-error'
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid--uniform">
                <div className="grid__item small--one-whole medium-up--full">
                  <div className="grid grid--uniform">
                    <div className="grid__item medium-up--one-half large-up--half">
                      <label>Swift Code *</label>
                      <input
                        type="text"
                        className="fullWidthInput"
                        value={bankSwiftCode || ''}
                        name="bankSwiftCode"
                        onChange={e => {
                          this.setFieldValue(e.target.value, 'bankSwiftCode')
                        }}
                        placeholder="Swift code"
                      />
                      {this.validator.message('bankSwiftCode', this.state.bankSwiftCode, 'required|max:16', {
                        messages: { default: 'Please enter swift code and it should have max 16 character.' },
                        className: 'form-error'
                      })}
                    </div>

                    <div className="grid__item medium-up--one-half large-up--half">
                      <label>City *</label>
                      <Select
                        placeholder="City"
                        options={cityOptions} //  hard code data
                        // options={cityOptions} dynamic data
                        // onChange={this.handleLocationChange}
                        onChange={value => {
                          //console.log("bank_country",value);
                          this.setFieldValue(value.value, 'bankCity')
                          // this.setState({ selectedCity: value });
                        }}
                        value={selectedCity}
                        nam="bankCity"
                        isClearable
                        className="react-select"
                        classNamePrefix="select"
                      />

                      {this.validator.message('bankCity', this.state.bankCity, 'required', { messages: { default: 'Please enter city.' }, className: 'form-error' })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid--uniform">
                <div className="grid__item small--one-whole medium-up--full">
                  <div className="grid grid--uniform">
                    <div className="grid__item medium-up--one-half large-up--half">
                      <label>Country *</label>
                      <Select
                        placeholder="Country"
                        options={contries} //  hard code data
                        // options={cityOptions} dynamic data
                        // onChange={this.handleLocationChange}
                        onChange={value => {
                          //console.log("bank_country",value);
                          this.setFieldValue(value.value, 'bankCountry')
                          this.setState({ selectedCountry: value })
                        }}
                        value={selectedCountry}
                        nam="bankCountry"
                        isClearable
                        className="react-select"
                        classNamePrefix="select"
                      />
                      {/*<input type="text" className="fullWidthInput" name="bank_country"   placeholder="Country" />*/}
                      {this.validator.message('bankCountry', this.state.bankCountry, 'required', { messages: { default: 'Please select country.' }, className: 'form-error' })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-footer displyflex" style={{ marginTop: '80px' }}>
          <button
            type="submit"
            disabled={this.state.bankdetailsProceed}
            onClick={async e => {
              e.preventDefault()
              this.setState({ bankdetailsProceed: true })
              try {
                await this.submitBankDetail()
                this.setState({ bankdetailsProceed: false })
              } catch (error) {
                this.setState({ bankdetailsProceed: false })
              }
            }}
            className="btn btn--darker form-footer--right margin-auto"
          >
            Save
          </button>
        </div>
      </form>
    )
  }
}


const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  countryOptions: state.user.countryCode.countryList ? state.user.countryCode.countryList.list : null
})

const mapDispatchToProps = dispatch => ({
  updateUserBankDetails: state => dispatch(updateUserBankDetails(state)),
  userActions: bindActionCreators(userActionCreators, dispatch)
  //sendVerifyMobileLink: state => dispatch(sendVerifyMobileLink(state)),
  //verifyMobile: (id, otp) => dispatch(verifyMobile(id, otp))
})
export default connect(mapStateToProps, mapDispatchToProps)(BankDetails)
//export default BankDetails;
